import React, { useState } from "react";
import styled from "styled-components";
import Box from '@material-ui/core/Box';
import { Form as FinalForm } from 'react-final-form';
import get from 'lodash/fp/get';
import { 
  DialogTitle,
  DialogContent,
  DialogActions,
  Button, 
  CircularProgress,
  Link
} from "@material-ui/core";
import { BlobProvider } from '@react-pdf/renderer';
import { PDFPreview } from "../../../../../../components/sales-meeting-form/PDFPreview";

const ProgressText = styled.span`
	font-size: 20px;
	margin-top: 10px;
`;

const PdfContainer = ({ onSubmit, initialValues, id, title, onClose }) => {
  const documentTitle = get('title', initialValues);

  return (
    <FinalForm onSubmit={onSubmit} initialValues={initialValues}>
    {({ handleSubmit }) => (
      <Box onSubmit={handleSubmit} component="form" id={id}>
        <DialogTitle>{title}</DialogTitle>
          <BlobProvider document={<PDFPreview {...initialValues} />}>
            {({ blob, url, loading, error }) => {
              return(
                <>
                <DialogContent>
                  <Choose>
                    <When condition={loading}>
                      <Box style={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        minHeight: '500px',
                        alignItems: 'center'}}>
                        <CircularProgress />
                        <ProgressText>Generating PDF...</ProgressText>
                      </Box>
                      {/* <ProgressSpinner message={"Generating PDF..."} subtitle={"This may take some time..."}/> */}
                    </When>
                    <When condition={error}>
                      <div>Error loading pdf</div>
                    </When>
                    <Otherwise>
                      <iframe src={`${url}#toolbar=0&navpanes=0`} style={{width:"100%", height: '80vh'}}/>
                    </Otherwise>
                  </Choose>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose}>Cancel</Button>
                  <Button variant="contained" color="primary" disabled={!url}>
                    <Link href={url} download={documentTitle || "Sales-report.pdf"} underline="none" color="inherit">
                      Download
                    </Link>
                  </Button>
                </DialogActions>
                </>
              )
            }}
          </BlobProvider>
      </Box>
    )}
    </FinalForm>
  );
};

PdfContainer.defaultProps = {
  submitLabel: 'Download'
}

export default PdfContainer;