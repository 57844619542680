import React, { useState } from 'react';
import styled from "styled-components";

import { AsyncPaginate, withAsyncPaginate } from 'react-select-async-paginate';
import InputLabel from "@material-ui/core/InputLabel";

import { default as actions } from '../../redux/actions/amplifyActions';
import { default as useActions } from '../../utils/use-actions';
import SelectField from '../shared/select-field';

// const AsyncPaginateSelect = styled(AsyncPaginate)`
//     margin-top: ${props => props.marginTop};

//     .css-yk16xz-control {
//       height: ${props => props.height};
//     }

//     .css-1pahdxg-control {
//       height: ${props => props.height};
//     }

//     .css-f0arjc-control {
//       height: ${props => props.height};
//     }
// `;

const AsyncPaginateSelect = withAsyncPaginate(SelectField);

const ContactSelectField = ({
    input,
    meta,
    label,
    placeholder,
    isCreateable,
    isClearable,
    isMulti,
    height,
    marginTop,
    ...rest
}) => {
    const [progress, setProgress] = useState(false);
    const { searchContactsList, getContactsWithNameAndEmail } = useActions(actions);
    const [token, setToken] = useState(null);

    const loadVirtualizedOptions = async (q, page) => {
      const { options, hasMore } = await loadOptions(q, page);
      return {
        options: options,
        hasMore
      }
    }

    const loadOptions = async (search, prevPage) => {

      let searchedOptions = [];
      let hasMore;

      if(!search){
        const data = await getContactsWithNameAndEmail({ 
          sortDirection: 'ASC',
          type: 'Contact',
          limit: 20, 
          nextToken: token
        });
        searchedOptions = data.value.listContactsByFirstName.items;
        setToken(data.value.listContactsByFirstName.nextToken);
        hasMore = data.value.listContactsByFirstName.nextToken;
        console.log(searchedOptions);
      }else {
        const data = await searchContactsList({
          limit: 20,
          filter: {
            full_name: { matchPhrasePrefix: search.toLowerCase()}
          },
          nextToken: token
        });
        searchedOptions = data.value.searchContacts.items;
        setToken(data.value.searchContacts.nextToken);
        hasMore = data.value.searchContacts.nextToken;
        console.log(searchedOptions);
      }

      return {
        options: searchedOptions,
        hasMore
      }
    }



    return (
        <React.Fragment>
            <If condition={label}>
                <InputLabel shrink>{label}</InputLabel>
            </If>
            <AsyncPaginateSelect
              id="custom-select"
              {...input}
              {...rest}
              placeholder={placeholder}
              isClearable={isClearable}
              isMulti={isMulti}
              height={height}
              marginTop={marginTop}
              loadOptions={loadVirtualizedOptions}
              debounceTimeout={1000}
              meta={meta}
              input={input}
            /> 
        </React.Fragment>  
    )
}

ContactSelectField.defaultProps = {
  height: '50px',
  marginTop: '0px'
}

export default ContactSelectField;