import React, { useState } from 'react';
import styled from "styled-components";
import reject from "lodash/reject";

import { AsyncPaginate } from 'react-select-async-paginate';
import InputLabel from "@material-ui/core/InputLabel";

import { default as actions } from '../../../redux/actions/amplifyActions';
import { default as useActions } from '../../../utils/use-actions';


const AsyncPaginateSelect = styled(AsyncPaginate)`
    margin-top: ${props => props.marginTop};

    .css-yk16xz-control {
      height: ${props => props.height};
    }

    .css-1pahdxg-control {
      height: ${props => props.height};
    }

    .css-f0arjc-control {
      height: ${props => props.height};
    }
`;

const EmployeeSelectField = ({
    input,
    meta,
    label,
    placeholder,
    isCreateable,
    isClearable,
    isMulti,
    height,
    marginTop,
    ...rest
}) => {
    const [progress, setProgress] = useState(false);
    const { getEmployees } = useActions(actions);
    const [token, setToken] = useState(null);

    const loadVirtualizedOptions = async (q, page) => {
      const { options, hasMore } = await loadOptions(q, page);
      return {
        options: options,
        hasMore
      }
    }

    const loadOptions = async (search, prevPage) => {

      let searchedOptions = [];
      let hasMore;

      if(!search){
        const data = await getEmployees({ 
          limit: 20, 
          nextToken: token
        });
        searchedOptions = reject(data.value.listEmployees.items, ['jobTitle', 'Admin']);
        setToken(data.value.listEmployees.nextToken);
        hasMore = data.value.listEmployees.nextToken;

      }else {
        const data = await getEmployees({
          limit: 20,
          filter: {
            name: { beginsWith: `${search}`}
          },
          nextToken: token
        });
        searchedOptions = reject(data.value.listEmployees.items, ['jobTitle', 'Admin']);
        setToken(data.value.listEmployees.nextToken);
        hasMore = data.value.listEmployees.nextToken;
      }

      return {
        options: searchedOptions,
        hasMore
      }
    }



    return (
        <React.Fragment>
            <If condition={label}>
                <InputLabel shrink>{label}</InputLabel>
            </If>
            <AsyncPaginateSelect
              id="employee-select"
              {...input}
              {...rest}
              placeholder={placeholder}
              isClearable={isClearable}
              isMulti={isMulti}
              height={height}
              marginTop={marginTop}
              loadOptions={loadVirtualizedOptions}
              debounceTimeout={1000}
            /> 
        </React.Fragment>  
    )
}

EmployeeSelectField.defaultProps = {
  height: '50px',
  marginTop: '0px'
}

export default EmployeeSelectField;