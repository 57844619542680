import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image, PDFDownloadLink } from '@react-pdf/renderer';
const styles = StyleSheet.create({
  page: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 90,
    paddingTop: 15,
    paddingLeft: 15,
    paddingRight: 15
  },
  body: {
    margin: 15
  },
  heading: {
    textAlign: 'center',
    marginBottom: 40,
    fontSize: 12,
    // fontWeight: 900,
    letterSpacing: 1.2,
    fontFamily: 'Open Sans Bolder'
  },
  subheading: {
    // marginLeft: 15,
    // marginRight: 15,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  labels: {
    paddingBottom: 10,
    fontSize: 8,
    letterSpacing: 1.2,
    fontFamily: 'Open Sans Bold'
  },
  quotesAndLeads: {
    marginBottom: 8,
    minHeight: 100,
    fontSize: 8,
    fontFamily: 'Open Sans'
  },
  content: {
    minHeight: 60,
    marginBottom: 8,
    fontSize: 8,
    fontFamily: 'Open Sans'
  },
  table: {
    paddinTop: 30,
    paddingBottom: 25
  },
  tableRow: { 
    margin: "auto",
    flexDirection: "row"
  },
  tableColumn: {
    borderStyle: 'solid',
    borderRightWidth: .75,
    borderBottomWidth: .75
  },
  tableHeader: {
    borderStyle: 'solid',
    borderTopWidth: .75,
    borderBottomWidth: .75,
    borderRightWidth: .75
  },
  tableCellLabel: { 
    margin: "auto", 
    marginTop: 2.5,
    marginBottom: 2.5, 
    
    width: 265, 
    textAlign: 'center',
    fontSize: 8,
    letterSpacing: 1.2,
    fontFamily: 'Open Sans Bold'
  },
  tableCellText: { 
    margin: "auto", 
    marginTop: 2.5,
    marginBottom: 2.5,
    minHeight: 10, 
    width: 265, 
    textAlign: 'center',
    fontSize: 8,
    fontFamily: "Open Sans"
  },
  downloadLink: {
    color: 'white',
    textDecoration: 'none'
  },
  image: {
    height: 'auto',
    width: 600,
    alignSelf: 'center',
    marginBottom: 10
  },
  footer: {
    alignSelf: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    // marginBottom: 8,
    display: 'flex',
    flexDirection: 'column',
  },
  footerText: {
    paddingBottom: 2,
    paddingRight: 2,
    fontSize: 6,
    fontFamily: 'Open Sans Light'
  },
  footerContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingTop: 5
  },
  footersubContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  pageNumber: {
    position: 'absolute',
    bottom: 10,
    right: 20,
    fontSize: 8,
    fontFamily: 'Open Sans Light'
  },
  text: {
    fontSize: 8,
    fontFamily: 'Open Sans'
  }
});
const source = "http://fonts.gstatic.com/s/opensans/v13/IgZJs4-7SA1XX_edsoXWog.ttf"
const lightSource = "http://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTS3USBnSvpkopQaUR-2r7iU.ttf" // 300
const boldSource = "http://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSi3USBnSvpkopQaUR-2r7iU.ttf" // 600
const bolderSource = "http://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzC3USBnSvpkopQaUR-2r7iU.ttf" // 700

Font.register({
  family: 'Open Sans',
  src: source
});
Font.register({
  family: 'Open Sans Light',
  src: lightSource
});
Font.register({
  family: 'Open Sans Bold',
  src: boldSource
});
Font.register({
  family: 'Open Sans Bolder',
  src: bolderSource
});

const PDFPreview = ({
  notes,
  scheduledPresentations,
  quotesAndLeads,
  lastWeekValues,
  nextWeekValues,
  username,
  date
}) => {
  const maxNumOfRows = Math.max(lastWeekValues.length, nextWeekValues.length);
  const displayRows = () => {
    const rows = [];
    for(let i=0; i< Math.max(maxNumOfRows, 8); i++) {
      rows.push(
        <View style={styles.tableRow}>
          <View style={[styles.tableColumn, { borderLeftWidth: 1 }]}>
            <Text style={styles.tableCellText}>{lastWeekValues[i]}</Text>
          </View>

          <View style={styles.tableColumn}>
            <Text style={styles.tableCellText}>{nextWeekValues[i]}</Text>
          </View>
        </View>
      );
    };
    return rows;
  };

  return (
    <Document title="Weekly Sales Meeting Report">
      <Page wrap style={styles.page}>
        <View style={styles.body}>
          <div style={styles.heading}>
            <Text>33SIXTY FLEXFORM SF/LA</Text>
            <Text>WEEKLY SALES MEETING REPORT</Text>
          </div>
          <div style={styles.subheading}>
            <Text style={styles.labels}>SALESPERSON: <Text style={styles.text}>{username}</Text></Text>
            <Text style={styles.labels}>DATE: <Text style={styles.text}>{date}</Text></Text>
          </div>
          <View style={styles.table}>
            <Text style={[styles.labels, { textAlign: 'center' }]}>WEEKLY OUTREACH</Text>
            <View style={styles.tableRow}> 
              <View style={[styles.tableHeader, {borderLeftWidth: 1}]}> 
                <Text style={styles.tableCellLabel}>LAST WEEK</Text> 
              </View> 
              <View style={styles.tableHeader}> 
                <Text style={styles.tableCellLabel}>THIS WEEK</Text> 
              </View>
            </View>
            {displayRows()}
          </View>
          <View>
            <Text style={styles.labels}>NOTES:</Text>
            <Text style={styles.content}>{notes}</Text>
          </View>

          <View>
            <Text style={styles.labels}>SCHEDULED PRESENTATIONS OR MEETINGS:</Text>
            <Text style={styles.content}>{scheduledPresentations}</Text>
          </View>

          <View>
            <Text style={styles.labels}>PROMISING INFORMAL QUOTES / GOOD LEADS:</Text>
            <Text style={styles.quotesAndLeads}>{quotesAndLeads}</Text>
          </View>
        </View>
        
        <View style={styles.footer} fixed>
          <Image style={styles.image} src="/static/images/33SIXTY_ new flexform logo.footers-10.jpg"/>
          {/* <div style={styles.footerContent}>
            <div>
              <Text style={styles.footerText}>FLEXFORM SAN FRANCISCO |</Text>
              <Text style={styles.footerText}>FLEXFORM LOS ANGELES   |</Text>
            </div>
            <div style={styles.footersubContent}>
              <Text style={styles.footerText}>145 rhode island street, san francisco, ca 94103 | 415.800.6576</Text>
              <Text style={styles.footerText}>308 n robertson boulevard, los angeles, ca 90048 | 310.424.5460</Text>
              <Text style={styles.footerText}>info@33sixty.com | www.33sixty.com</Text>
            </div>
          </div> */}
          <Text
            style={styles.pageNumber}
            render={({pageNumber, totalPages}) => (
              `${pageNumber} / ${totalPages}`
            )}
            fixed
          >
          </Text>
        </View>
      </Page>
    </Document>
  )};

  const DownloadLink = ({
    notes,
    scheduledPresentations,
    quotesAndLeads,
    lastWeekValues,
    nextWeekValues,
    username,
    date
  }) => (
    <div>
      <PDFDownloadLink 
        style={styles.downloadLink}
        document={
          <PDFPreview
            notes={notes}
            scheduledPresentations={scheduledPresentations}
            quotesAndLeads={quotesAndLeads}
            lastWeekValues={lastWeekValues}
            nextWeekValues={nextWeekValues}
            username={username}
            date={date}
          />
        } 
        fileName="sales-meeting-report.pdf"
      >
        {({loading}) =>
          loading ? 'Loading PDF...' : 'Download PDF'
        }
      </PDFDownloadLink>
    </div>
  );

export {PDFPreview, DownloadLink};