import React from "react";
import { 
  DialogTitle,
  DialogContent,
  DialogContentText,
  Dialog
} from "@material-ui/core";
import {
  FormContainer,
  PdfContainer
} from './components';

const DialogContainer = (props) => {
  const { children, open, onClose, onKill, variant, maxWidth } = props;

  return(
    <Dialog open={open} onClose={onClose} onExited={onKill} fullWidth maxWidth={maxWidth}>
      <Choose>
        <When condition={variant === 'form'}>
          <FormContainer {...props} onClose={onClose} />
        </When>
        <When condition={variant === 'pdf'}>
          <PdfContainer {...props} onClose={onClose} />
        </When>
        <Otherwise>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.content}
          </DialogContentText>
        </DialogContent>
        </Otherwise>
      </Choose>
    </Dialog>
  )
};

DialogContainer.defaultProps = {
  maxWidth: "sm"
}

export default DialogContainer;