import React from "react";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core';
import CustomerContactForm from '../../../../../../components/customer-contact-form';
import CompanyForm from "../../../../../../components/company-form/CompanyForm";
import CustomerPipelineForm from "../../../../../../components/contact-details/PipelineCard/components/CustomerPipelineForm";

const FormContainer = ({ onSubmit, form, id, submitLabel, title, onClose, ...props }) => {
  
  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Choose>
          <When condition={form === 'contact'}>
            <CustomerContactForm onSubmit={onSubmit} id={id} disableInnerSubmit={true} {...props } />
          </When>
          <When condition={form === 'company'}>
            <CompanyForm onSubmit={onSubmit} id={id} disableInnerSubmit={true} {...props } />
          </When>
          <When condition={form === 'pipeline'}>
            <CustomerPipelineForm onSubmit={onSubmit} id={id} disableInnerSubmit={true} {...props } />
          </When>
          <Otherwise>
            <DialogContentText>no form passed.</DialogContentText>
          </Otherwise>
        </Choose>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit" form={id} variant="contained" color="primary">{submitLabel}</Button>
      </DialogActions>
    </>
  );
};

FormContainer.defaultProps = {
  submitLabel: 'Submit',
  id: 'form-dialog'
}

export default FormContainer;