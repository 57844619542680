import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import moment from 'moment';
import { Form, FormSpy, Field as FinalField } from 'react-final-form';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import get from "lodash/fp/get";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import InputField from '../../../shared/input-field';
import SelectField from '../../../shared/select-field';
import CurrencyField from '../../../shared/currency-field';
import EmployeeSelectField from '../../../shared/employee-select-field';
import { PIPELINE_PHASE, CLOSED_LOST_OPTIONS } from '../../../../utils/constants';
import ContactSelectField from '../../../contact-select-field/ContactSelectField';

const Field = ({ item, xs, sm, md, ...props }) => (
  <Grid item xs={xs} sm={sm} md={md}>
      <FinalField {...props} />
  </Grid>
);

const CheckboxField = ({ label, input, ...rest }) => (
  <FormControlLabel
      style={{padding: 0, paddingTop: 30}}
      control={
        <Checkbox
          {...input} 
          {...rest}
          color="primary"
          checked={input.checked}
        />
      }
      label={label}
  />
);

const Condition = ({ when, is, initialValue, children }) => (
  <FinalField name={when} subscription={{ value: true }}>
    {({ input: { value, ...rest } }) => {
      return((get('id',value) !== get('id',initialValue)) && (get('id',value)) ? children : null)
    }}
  </FinalField>
);

const CustomerPipelineForm = ({
  onSubmit,
  id,
  initialValues,
  newPipeline,
}) => {
  const [enableNotification, setEnableNotification] = useState(false);
  const required = value => (value ? undefined : 'Required');
  if (initialValues && !newPipeline) {
    if (typeof(initialValues.phase) == 'string') {
      initialValues.phase = PIPELINE_PHASE.find(e => e.value === initialValues.phase);
    }
    if (initialValues.closedLostReason) {
      if (typeof(initialValues.closedLostReason) == 'string') {
        initialValues.closedLostReason = CLOSED_LOST_OPTIONS.find(item => item.label === initialValues.closedLostReason)
      }
    }
  };

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit }) => {
        return (
          <Box 
            onSubmit={handleSubmit}
            component="form"
            id={id}
            padding={2}
          >
            <FormSpy
              subscription={{ values: true, dirtyFields: true }}
              onChange={({ values, dirtyFields }) => {
                if (get('assignedTo.id', values) !== get('assignedTo.id', initialValues)) {
                    setEnableNotification(true);
                }else {
                    setEnableNotification(false);
                }
                if (values.assignedTo === null) {
                    setEnableNotification(false);
                    values.sendNotification = false;
                }
                if (initialValues && dirtyFields.phase) {
                  values.history = {
                    phase: values.phase?.value,
                    createdAt: moment().toISOString(),
                    editedBy: { id: Auth.user.attributes.sub, name: Auth.user.attributes.name },
                    description: "Phase"
                  }
                } else {
                  values.history = null;
                }
              }}
            />
            <Grid container spacing={2} wrap>
              <Field
                sm={6}
                name="project_title"
                label="Project"
                component={InputField}
                parse={value => value == "" ? "" : value}
                fullWidth
                validate={required}
              />
              <Field
                sm={6}
                component={ContactSelectField}
                name="contact"
                label="Contact"
                marginTop="8px"
                getOptionLabel={get("full_name")}
                getOptionValue={get("id")}
                validate={required}
              />
              <Field
                md={6}
                name="phase"
                label="Phase"
                component={SelectField}
                options={PIPELINE_PHASE}
                getOptionLabel={get("label")}
                getOptionValue={get("id")}
                allowNull
                fullWidth
                validate={required}
              />
              <Field
                md={6}
                name="closedLostReason"
                label="Closed Lost Reason"
                component={SelectField}
                options={CLOSED_LOST_OPTIONS}
                getOptionLabel={get("label")}
                getOptionValue={get("id")}
                allowNull
                fullWidth
                // validate={required}
              />
              <Field
                md={6}
                name="sale_amount"
                label="Sale Amount"
                component={CurrencyField}
                fullWidth
                parse={value => value == "" ? "" : value}
              />
              <Field
                sm={enableNotification?8:12} md={enableNotification?4:6}
                component={EmployeeSelectField}
                name="assignedTo"
                label="Assigned to"
                marginTop="8px"
                getOptionLabel={get("name")}
                getOptionValue={get("id")}
                validate={required}
              />
              <Condition when="assignedTo" is={!null} initialValue={initialValues?.assignedTo}>
                <Field
                  sm={4}
                  md={2}
                  name="sendNotification"
                  type="checkbox"
                  component={CheckboxField}
                  label="Send notification"
                />
              </Condition>
              <If condition={newPipeline}>
                <Field
                  sm={12}
                  name="addToReport"
                  type="checkbox"
                  component={CheckboxField}
                  label="Add to Report"
                />
              </If>
            </Grid>
          </Box>
        )
      }}
    </Form>
  )
};

export default CustomerPipelineForm;