import React, { useState } from "react";
import styled from "styled-components";
import { Form, FormSpy, Field as FinalField } from "react-final-form";
import get from "lodash/fp/get";
import { Auth } from "aws-amplify";

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import { Typography } from "@material-ui/core";

import InputField from '../shared/input-field';
import SelectField from '../shared/select-field';
import ToggleField from '../shared/toggle-field';
import PhoneField from '../shared/phone-field';

import { EMAIL_PATTERN, WEBURL_PATTERN, VIP_DATA } from '../../utils/constants';
import { default as STATES } from '../../utils/states-us';
import { useEmployees } from "../../utils/crm/use-employee";
import { useGroups } from "../../utils/crm/use-group";
import phoneFormatting from "../../utils/formatting";
import DivisionSelectField from "../division-select-field/DivisionSelectField";

const Field = ({ item, xs, sm, md, ...props }) => (
  <Grid item xs={xs} sm={sm} md={md}>
      <FinalField {...props} />
  </Grid>
);

const GridGroup = ({title, description, children}) => (
  <Grid sm={12} container spacing={1} style={{marginBottom: 10, marginTop: 10}}>
      <Grid item sm={12} md={3}>
          <Typography variant="subtitle1" style={{fontWeight: 600}}>{title}</Typography>
          <Typography variant="caption" >{description}</Typography>
      </Grid> 
      <Grid item sm={12} md={9} container spacing={1}>
          {children}
      </Grid>
      <Grid item xs={12}>
          <Divider />
      </Grid>
  </Grid>
);

const CheckboxField = ({ label, input, ...rest }) => (
  <FormControlLabel
      style={{padding: 0, paddingTop: 30}}
      control={
        <Checkbox
          {...input} 
          {...rest}
          color="primary"
          checked={input.checked}
        />
      }
      label={label}
  />
);

const Condition = ({ when, is, initialValue, children }) => (
  <FinalField name={when} subscription={{ value: true }}>
    {({ input: { value, ...rest } }) => {
      return((get('id',value) !== get('id',initialValue)) && (get('id',value)) ? children : null)
    }}
  </FinalField>
)

const CompanyForm = ({
  onSubmit,
  id,
  newCompany,
  initialValues,
  labelSubmit,
  disableInnerSubmit
}) => {
  const [helperTextGroup, setHelperTextGroup] = useState({ mailings: null, region: null, industry: null });
  const required = value => (value ? undefined : 'Required');
  const mustBeEmail = value => !value? undefined: ((EMAIL_PATTERN.test(value) ? undefined : 'Email not valid'));
  const mustBeURL = value => !value? undefined: ((WEBURL_PATTERN.test(value) ? undefined : 'URL not valid'));
  const [{ employees }] = useEmployees();
  const [{ groups }] = useGroups();
  const userEmail = Auth.user.attributes.email;

  if (initialValues && !initialValues.length && !newCompany){
    const initialGroups = get('groups', initialValues);
    initialValues.mailingGroups = initialGroups ? initialGroups.find(el => el.type === 'mailings') : {};
    initialValues.regionGroups = initialGroups ? initialGroups.find(el => el.type === 'region') : {};
    initialValues.industryGroups = initialGroups ? initialGroups.find(el => el.type === 'industry') : {};
    initialValues.state = STATES.find(el => el.value === initialValues.state);
    initialValues.vip_type = VIP_DATA.find(el => el.name === initialValues.vip_type)
    initialValues.sendNotification = false;
  }

  if (newCompany){
    initialValues = { ...initialValues};
    initialValues.assignees = employees.find(el => el.email === userEmail);
  }

  const mailingGroupOptions = groups.filter(el => el.type === 'mailings');
  const regionGroupOptions = groups.filter(el => el.type === 'region');
  const industryGroupOptions = groups.filter(el => el.type === 'industry');

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit }) => (
        <Box onSubmit={handleSubmit} component="form" padding={3} id={id}>
          <FormSpy
            subscription={{ values: true }}
            onChange={({ values }) => { 
              const mailchimpMailings = get('mailchimp_list_id', values.mailingGroups);
              const mailchimpRegion = get('mailchimp_list_id', values.regionGroups);
              const mailchimpIndustry = get('mailchimp_list_id', values.industryGroups);
              const textGroup = 'Group synced with a Mailchimp audience';
              setHelperTextGroup({ 
                mailings: mailchimpMailings ? textGroup : null,
                region: mailchimpRegion ? textGroup : null,
                industry: mailchimpIndustry ? textGroup : null,
              });
            }}
          />

          <Grid container spacing={2}>
            <GridGroup title="Company Details">            
              <Field
                sm={12} md={12}
                name="name"
                component={InputField}
                label="Name"
                fullWidth
                validate={required}
              />
              <Field
                sm={12} md={6}
                component={InputField}
                name="email"
                fullWidth
                label="Email Address"
                validate={mustBeEmail}
                parse={value => value == "" ? "" : value}
              />
              <Field
                sm={12} md={6}
                component={InputField}
                name="website"
                fullWidth
                label="Website"
                validate={mustBeURL}
                parse={value => value == "" ? "" : value}
              />
              <Field
                sm={12} md={6}
                component={PhoneField}
                name="phone"
                fullWidth
                label="Phone #"
                parse={(value) => {return phoneFormatting(value)}}
              />
            </GridGroup>
            {/* <Field
              sm={12} md={1}
              component={ToggleField}
              name="vip"
              type="checkbox"
              fullWidth
              label="VIP"
            /> */}
            <GridGroup title="Status">
              <Field
                sm={12} md={6}
                name="vip_type"
                label="VIP Level"
                component={SelectField}
                options={VIP_DATA}
                getOptionLabel={get("name")}
                getOptionValue={get("id")}
                allowNull
                fullWidth
              />
            </GridGroup>
            <GridGroup title="Groups">
              <Field
                sm={12} md={4}
                name="mailingGroups"
                label="Mailings"
                component={SelectField}
                options={mailingGroupOptions}
                getOptionLabel={get("name")}
                getOptionValue={get("id")}
                fullWidth
                helperText={helperTextGroup.mailings}
              />
              <Field
                sm={12} md={4}
                name="regionGroups"
                label="Region"
                component={SelectField}
                options={regionGroupOptions}
                getOptionLabel={get("name")}
                getOptionValue={get("id")}
                fullWidth
                helperText={helperTextGroup.region}
              />
              <Field
                sm={12} md={4}
                name="industryGroups"
                label="Industry"
                component={SelectField}
                options={industryGroupOptions}
                getOptionLabel={get("name")}
                getOptionValue={get("id")}
                fullWidth
                helperText={helperTextGroup.industry}
              />
            </GridGroup>
            <GridGroup title="Brands">
              <Field
                sm={12} md={6}
                name="divisions"
                label="Brands"
                component={DivisionSelectField}
                getOptionLabel={get("label")}
                getOptionValue={get("id")}
                marginTop="8px"
                fullWidth
              />
            </GridGroup>
            <GridGroup title="Assignment">
              <Field
                sm={12} md={6}
                name="assignees"
                label="Assigned to"
                component={SelectField}
                options={employees}
                getOptionLabel={get("name")}
                getOptionValue={get("id")}
                validate={required}
                fullWidth
                isMulti={true}
              />
            </GridGroup>
            <GridGroup title="Company Address">
              <Field
                sm={12} md={6}
                component={InputField}
                name="street_address"
                fullWidth
                label="Street Address"
                parse={value => value == "" ? "" : value}
              />
              <Field
                sm={12} md={6}
                component={InputField}
                name="city"
                fullWidth
                label="City"
                parse={value => value == "" ? "" : value}
              />
              <Field
                sm={12} md={6}
                name="state"
                label="State"
                component={SelectField}
                options={STATES}
                getOptionLabel={get("name")}
                getOptionValue={get("value")}
                allowNull
                fullWidth
              />
              <Field
                sm={12} md={6}
                component={InputField}
                name="zip_code"
                fullWidth
                label="Zip Code"
                parse={value => value == "" ? "" : value}
              />
              <Field
                sm={12} md={6}
                component={InputField}
                name="country"
                fullWidth
                label="Country"
                parse={value => value == "" ? "" : value}
              />
            </GridGroup>
          </Grid>
          <Box marginTop={4}>
            <If condition={!disableInnerSubmit}>
              <Button variant="contained" color="primary" type="submit">
                  {labelSubmit}
              </Button>
            </If>
          </Box>
        </Box>
      )}
    </Form>
  );
};

CompanyForm.Root = styled.form`
    width: 320px;
`;

export default CompanyForm;
