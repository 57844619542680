import React, { useState } from "react";
import NumberFormat from 'react-number-format';

import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import AssistantIcon from '@material-ui/icons/Assistant';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    flex: 1,
    height: 48,
    width: '100%',
    padding: '10px',
    marginTop: '24px',
    border: '1px solid #bdbdbd',
    borderRadius: '4px',
    outline: 'none',
    fontSize: '16px',
    transition: 'border-color 0.3s, box-shadow 0.3s',
    '&:focus': {
      borderColor: '#1976d2',
      boxShadow: '0 0 3px rgba(25, 118, 210, 0.5)',
    },
  },
  inputWrapper: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none !important',
    },
  },
  formatButton: {
    display: 'flex',
    justifyContent: 'spaceAround',
  },
}));

function NumberFormatCustom(props) {
  const classes = useStyles();
  const { inputRef, onChange, value } = props;
  return (
    <NumberFormat
      className={classes.input}
      value={value}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}


const CurrencyField = ({
  label,
  helperText,
  multiline,
  rows,
  input,
  meta,
  autoFocus,
  placeholder,
  ...props
}) => {
  const [isFocus, setFocus] = useState(false);
  const hasError = meta? meta.error && meta.touched : null;

  const classes = useStyles();
  const [values, setValues] = useState("");

  const handleRoundInt = () => {
    input.onChange(Math.round(input.value / 10) * 10)
  };

  const handleChange = (event) => {
    setValues(event.target.value);
  };

  return (
    <FormControl {...props}
      error={hasError}
    >
      {label && (
        <InputLabel shrink htmlFor={input? input.name: null} focused={isFocus}>
          {label}
        </InputLabel>
      )}
      <Box className={classes.formatButton}>
        <TextField
          className={classes.inputWrapper}
          value={values}
          onChange={handleChange}
          name="sale_amount"
          id="sale_amount"
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
          fullWidth
          {...input}
          {...props}
        />
        <Tooltip title="Round" >
          <IconButton style={{height: '30%'}} color="primary" aria-label="auto-format" onClick={handleRoundInt}>
            <AssistantIcon />
          </IconButton>
        </Tooltip>
      </Box>

      {/* {(helperText || meta.error) && ( */}
        <FormHelperText id={`${input.name}-helper-text`}>
          <Choose>
            <When condition={meta.error && meta.touched}>{meta.error}</When>
            <When condition={helperText}>{helperText}</When>
          </Choose>
        </FormHelperText>
      {/* )} */}
    </FormControl>
  );
};


export default CurrencyField;